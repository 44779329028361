import Vue from 'vue';
import Vuex from 'vuex';

import modules from './modules';

//挂载Vuex
Vue.use(Vuex);

//创建VueX对象
const store = new Vuex.Store({
  modules,
  state: {},
  mutations: {},
  actions: {}
});

export default store;