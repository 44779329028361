const demo = {
  namespaced: true,
  state: {
    name: 'hello,world'
  },
  mutations: {
    SET_DEMO_NAME: (state, name) => {
      state.name = name;
    }
  },
  actions: {
    setName({ commit }, name) {
      commit('SET_DEMO_NAME', name);
    }
  }
}

export default demo;