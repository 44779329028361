import Vue from 'vue';
import App from './App';
import { router } from './router';
import axios from 'axios';
import store from './store';

Vue.prototype.$axios = axios;

new Vue({
  store,
  router,
  el: '#app',
  render: h => h(App)
});
